<template>
  <div>
    <div class="widgetContainer widgetContainer--center widgetContainer--scrollable yourOwnership">
      <div class="navigationHeader">
        <div class="navigationHeader__inner">
          <span
            class="icon-Arrow-big-left cancel"
            @click="goToPreviousScreen" />
          <p class="title">
            {{ $t('addOwnerPercentage_NavTitle') }}
          </p>
        </div>
      </div>

      <div class="widgetContainer__body">
        <el-form class="wise-form" v-model="formData"
                 @keyup.enter.native="submit()"
                 @submit.prevent.native>
          <div class="borderWrapper yourOwnership__switch-wrapper" v-if="false">
            <p class="wise-form__header">
              {{ $t('additionalOwnerPercentage_Title') }}
            </p>
            <div class="wise-form__text-with-switch">
              <p class="wise-forn__info">
                {{ $t('additionalOwnerPercentage_TextField_Descriptions') }}
              </p>
              <el-switch
                v-model="greaterThan25"
                active-color="var(--branding)"
                inactive-color="#dfdfe4" />
            </div>
          </div>

          <el-form-item
            prop="percentage"
            v-if="greaterThan25"
            class="is-no-asterisk column-input"
            :label="$t('additionalOwnerPercentage_TextField_Descriptions')">
            <el-input
              type="number"
              v-model="formData.percentage"
              v-if="greaterThan25"
              class="is-no-asterisk"
              maxlength="3" />
          </el-form-item>

          <p class="yourOwnership__verify-description wise-form__info">
            {{ $t('addOwnerPercentage_Descriptions') }}
          </p>
        </el-form>
      </div>
      <div class="widgetContainer__footer--fixed">
        <el-button type="primary" :disabled="isFormInValid" @click="submit" class="el-button__brand brand">
          {{ $t('addOwnerPercentage_NextButton') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import businessMixin from '../../mixins/business';

/** @see {@link https://www.sketch.com/s/d3a40e77-270b-44f7-b432-8276d76b17f4/a/3OorGrD} */
export default {
  name: 'OwnerOwnership',
  mixins: [businessMixin],
  data() {
    return {
      formData: {
        percentage: null
      },
      greaterThan25: true,
      ownerDetails: null
    };
  },
  computed: {
    ...mapGetters('kyb', ['getCreatePageOwnersDetail', 'getOwnersDetails']),
    isFormInValid() {
      const percentage = parseInt(this.formData.percentage);
      if (!this.greaterThan25) {
        return false;
      } else if (
        percentage >= 25 &&
        percentage <= 100 &&
        this.totalOwnerShip + percentage <= 100
      ) {
        return false;
      } else {
        return true;
      }
    },
    totalOwnerShip() {
      let total = 0;
      this.getOwnersDetails.forEach((o) => {
        total += parseInt(o.ownership);
      });
      return total;
    }
  },
  created() {
    if (this.getCreatePageOwnersDetail === null) {
      this.$router.push('/kyb/ownersList');
    }
  },
  methods: {
    ...mapActions('kyb', ['createOwner', 'submitOwnerKyc']),
    ...mapMutations('kyb', ['setCreatePageOwnerDetails']),
    submit() {
      if(this.isFormInValid) {
        return;
      }
      if (!this.greaterThan25) {
        this.formData.percentage = 0;
        //return //TODO: Need to change
      }
      /** @type {import('@m/kyb/store/state').kybState['createPageOwnerDetails']} */
      const owner = this.getCreatePageOwnersDetail;
      if (owner) {
        owner.ownership = this.formData.percentage;
        owner.title = 'other';

        const loader = this.showLoader();
        this.createOwner(owner)
          .then((res) => {
            this.setCreatePageOwnerDetails(null);
            setTimeout(() => {
              this.ownerKyc(res.id);
            }, 0);
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      }
    },
    /**
     * submit owner kyc
     */
    ownerKyc(ownerId) {
      const loader = this.showLoader();
      this.submitOwnerKyc(ownerId)
        .then(() => {
          setTimeout(() => {
            this.$router.push('/kyb/ownersList');
          }, 0);
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    }
  }
};
</script>

<style lang="scss">
.yourOwnership {
  &__switch-wrapper {
    .wise-forn__info {
      font-size: 14px;
      line-height: 20px;
      padding-right: 20px;
      width: 100%;
    }
    .wise-form__header {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 8px;
    }

    .wise-form__text-with-switch {
      padding-bottom: 0;
    }
  }

  .widgetContainer__body {
    overflow-y: auto;
  }

  .wise-form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__verify-description {
    padding-bottom: 60px;
    margin-top: auto;
  }

  .el-input-number.is-without-controls .el-input__inner {
    text-align: left;
  }
}
</style>
